import React from 'react'
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import './assets/libs/@mdi/font/css/materialdesignicons.min.css';
// import Index from './pages/index';
import Navbar from './component/Navbar/navbar'; 
import IndexSaas from './pages/index/indexSaas';
 
import Documentation from './pages/documentation';
import Changelog from './pages/changelog';

export default function App() {
    return (
        
        <BrowserRouter>
 
            <Routes>
                <Route path="/" element={<Navigate to="/index-saas" />} />
                {/* <Route exact path="/index" element={<Index />} />  */}
                <Route exact path="/navbar" element={<Navbar />} /> 
                <Route exact path="/index-saas" element={<IndexSaas />} />
                <Route exact path="/documentation" element={<Documentation />} />
                <Route exact path="/changelog" element={<Changelog />} />
             
            </Routes>
        
        </BrowserRouter>
    )
}
