import React from 'react'
import Navbar from '../component/Navbar/navbar'
import Footer from '../component/Footer/footer';


import { Link } from 'react-router-dom'

export default function Documentation() {
    return (
        <>
            <Navbar />
            <section className="relative table w-full py-32 lg:py-40 bg-gray-50 dark:bg-slate-800">
                <div className="container relative">
                    <div className="grid grid-cols-1 text-center mt-10">
                        <h3 className="text-3xl leading-normal font-semibold">오픈챗 이용안내</h3>
                    </div>
                </div>

                <div className="absolute text-center z-10 bottom-5 start-0 end-0 mx-3">
                    <ul className="tracking-[0.5px] mb-0 inline-block space-x-1">
                        <li className="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out hover:text-indigo-600"><Link to="/index">HOME</Link></li>
                        <li className="inline-block text-base mx-0.5 ltr:rotate-0 rtl:rotate-180"><i className="uil uil-angle-right-b"></i></li>
                        <li className="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out text-indigo-600"aria-current="page">오픈챗 이용안내</li>
                    </ul>
                </div>
            </section>
            <div className="relative">
                <div className="shape absolute sm:-bottom-px -bottom-[2px] start-0 end-0 overflow-hidden z-1 text-white dark:text-slate-900">
                    <svg className="w-full h-auto scale-[2.0] origin-top" viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                    </svg>
                </div>
            </div>


            <section className="relative md:py-24 py-16">
                <div className="container relative">
                    <div className="grid grid-cols-1 items-center gap-[30px]">
                        <div>
                            <h4 className="text-3xl font-semibold mb-4">오픈챗은 다음과 같은 활동을 금지하고 있습니다.</h4>

                            <p class="text-slate-400 text-base leading-8"><strong>Techwind</strong>
                            타인에게 잔혹감 또는 혐오감을 일으킬 수 있는 폭력적이고 자극적인 내용을 게시하는 행위      
                            </p>

							<p class="text-slate-400 text-base leading-8 font-semibold mt-2">
                            타인의 지식재산권 등을 침해하거나 모욕, 사생활 침해 또는 명예훼손 등 타인의 권리를 침해하는 행위</p>
                            <p class="text-slate-400 text-base leading-8 font-semibold mt-2">
                            과도한 욕설, 비속어 등을 계속하여 반복적으로 사용하여 심한 혐오감 또는 불쾌감을 일으키는 행위</p>
                            <p class="text-slate-400 text-base leading-8 font-semibold mt-2">
                            자살·동반자살을 목적으로 하거나 방조 또는 유인하는 행위</p>
                            <p class="text-slate-400 text-base leading-8 font-semibold mt-2">
                            타인에게 성적 수치심이나 불쾌감·혐오감을 유발할 수 있는 내용을 게시하는 행위</p>
                            <p class="text-slate-400 text-base leading-8 font-semibold mt-2">
                            성매매를 제안, 알선, 유인 또는 강요하고 관련 정보를 공유하는 행위</p>
                            <p class="text-slate-400 text-base leading-8 font-semibold mt-2">
                            아동 또는 청소년을 성적 유희의 대상으로 직접적이고 구체적으로 묘사한 내용의 게시하는 행위</p>
                            <p class="text-slate-400 text-base leading-8 font-semibold mt-2">
                            전기통신사업법에서 금지하는 불법촬영물등을 유통하는 행위</p>
                            <p class="text-slate-400 text-base leading-8 font-semibold mt-2">
                            도박 등 관련 법령상 금지되거나 형사처벌의 대상이 되는 행위를 직접 수행하거나, 혹은 이를 수행하도록 타인을 부추기거나(교사) 이를 수행하는 타인을 돕는(방조) 등 범죄 관련 직접적인 위험을 가하는 행위</p>
                            <p class="text-slate-400 text-base leading-8 font-semibold mt-2">
                            관련 법령에서 홍보, 광고, 판매 등을 금지하고 있는 물건 또는 서비스를 홍보, 광고, 판매하는 행위</p>
                            <p class="text-slate-400 text-base leading-8 font-semibold mt-2">
                            특정 종목에 대한 일방적인 홍보를 게시하는 행위</p>
                            <p class="text-slate-400 text-base leading-8 font-semibold mt-2">
                            통정 매매 등의 시세조종, 종목 매수 유도 등 주가 조작 행위 <br/>
※ 허위사실 유포는 자본시장과 금융투자업에 관한 법률 제176조 2항에 해당하는 시세조종행위로써 10년 이하의 징역 또는 그 위반행위로 얻은 이익 또는 회피한 손실액의 1배 이상 3배 이하에 상당하는 벌금에 처할 수 있습니다.<br/>
※ 불건전 게시물에 현혹되어 피해가 발생하지 않도록 주의해주세요. 불공정거래는 금융감독원과 한국거래소에 신고할 수 있습니다.<br/>
• 한국거래소 불공정거래 신고센터(Tel.1577-3360) : https://stockwatch.krx.co.kr/main/main.jsp <br/>
• 금융감독원 증권불공정거래신고센터 : https://www.fss.or.kr/fss/main/sub1Unfair.do?menuNo=200080<br/></p>
<p class="text-slate-400 text-base leading-8 font-semibold mt-2">
유료로 판매되는 이미지, 방송물, 영상, 음원, 만화(웹툰 포함), 소설(웹소설 포함), 게임, 소프트웨어 등에<br/> 대한 무료 다운로드 등 불법 복제를 권유하거나 불법 다운로드 링크 경로를 제공하는 행위</p>




                        </div>

                        <div>
                            <h4 className="text-3xl font-semibold mb-4">오픈챗 이용제한 안내</h4>
 
                            <p class="text-slate-400 text-base leading-8">오픈챗 서비스는 게시물 운영정책, 그 밖에 네이버 개별 서비스 운영정책 등에서 금지하는 행위를 하여서는 아니 되며, 
                            이를 위반하는 경우 관련 규정에 따라 이용제한을 받으실 수 있다는 점 유의하시기 바랍니다.  </p>

                           
 
 
                        </div>
                    </div>
                </div>
            </section >

            <Footer />

 
        </ >
    )
}
