import React, { useState } from 'react'
import { Link } from 'react-router-dom';

import classic01 from '../../assets/images/saas/화면01.png';
import classic02 from '../../assets/images/saas/화면02.png';
import classic03 from '../../assets/images/saas/화면03.png';
import foxLogo from '../../assets/images/foxLogo.svg';


import Navbar from '../../component/Navbar/navbar'

import {  Monitor } from 'feather-icons-react/build/IconComponents';
 
import "../../../node_modules/react-modal-video/css/modal-video.css";

const KeyFeature = [
    {
        Icon:Monitor,
        title:"별도의 앱 설치가 필요없는 채팅서비스입니다",
    },
    {
        Icon:Monitor,
        title:"문자발송 마켓팅시 링크클릭만으로 입장가능해요",
    },
    {
        Icon:Monitor,
        title:"문자마케팅시 회원유입 경로를 확인해보세요",
    },
    {
        Icon:Monitor,
        title:"새로운 오픈 채팅방 기능. 기존 채팅방의 불편함을 해결한 오픈 채팅방을 만나보세요.",
    }, 
    {
        Icon:Monitor,
        title:"클릭만 하면 시작되는 채팅. 회원가입없이 바로 채팅을 시작할 수 있습니다.",
    },
    {
        Icon:Monitor,
        title:"현재접속자가 궁금하세요? 현재 대화방 참여자 상황을 실시간으로 확인해보세요",
    },
    {
        Icon:Monitor,
        title:"카카오톡 텔레그램등의 계정생성의 불편함을 모두 해결했어요",
    },
    {
        Icon:Monitor,
        title:"인사봇을 이용해보세요. 원하는 프로필로 변경할 수 있습니다.",
    },
    {
        Icon:Monitor,
        title:"링크 클릭만으로 채팅방에 입장하고, 실시간으로 대화를 나누세요",
    },
    
]

export default function IndexSaas() {
    const [isOpen, setOpen] = useState(false)

    return (
        <>
            <Navbar />
            <section className="relative before:content-[''] before:absolute xl:before:-top-[30%] lg:before:-top-[50%] sm:before:-top-[80%] before:-top-[90%] before:-start-80 before:end-0 before:w-[140rem] before:h-[65rem] ltr:before:-rotate-12 rtl:before:rotate-12 before:bg-indigo-600/5 dark:before:bg-indigo-600/10 before:z-1 items-center overflow-hidden">
                <div className="container relative z-2">
                    <div className="grid grid-cols-1 md:mt-44 mt-32 text-center">
                        <div className="wow animate__animated animate__fadeIn">
                            <h4 className="font-bold lg:leading-normal leading-normal text-4xl lg:text-5xl mb-5"> 오픈챗 - 링크만으로 시작하는 오픈 채팅방 서비스 </h4>
                            <p className="text-slate-400 text-lg max-w-xl mx-auto">
                                오픈챗은 링크 클릭만으로 시작하는 오픈 채팅방 서비스입니다. 
                                설치나 별도의 회원가입 없이 쉽게 대화를 시작할 수 있습니다. 
                                링크 기반의 마케팅을 통해 회원 유입을 추적하고 접속 여부를 쉽게 판별할 수 있습니다.</p>

                            <div className="mt-6">
                                <Link 
                                
                                to="https://t.me/euns0" className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md">1:1 문의하기</Link>

                                <p className="text-slate-400 text-sm mt-3">채팅방운영이 필요하시면 지금 문의주세요</p>
                            </div>
                        </div>
                        <div className="home-dashboard mt-8 z-3 wow animate__animated animate__fadeIn">
                            <img src={classic01} alt="" className="mover" />
                        </div>
                    </div>

                    <div className="bg-indigo-600 w-8 h-16 z-2 absolute start-2 lg:bottom-28 md:bottom-36 sm:bottom-40 bottom-16"></div>
                    <div className="bg-indigo-600/20 w-8 h-16 z-2 absolute start-12 lg:bottom-32 md:bottom-40 sm:bottom-44 bottom-20"></div>

                    <div className="bg-indigo-600/20 w-8 h-16 z-2 absolute end-12 xl:bottom-[420px] lg:bottom-[315px] md:bottom-[285px] sm:bottom-80 bottom-32"></div>
                    <div className="bg-indigo-600 w-8 h-16 z-2 absolute end-2 xl:bottom-[440px] lg:bottom-[335px] md:bottom-[305px] sm:bottom-[340px] bottom-36"></div>
                </div>
            </section>

            <section className="py-6">
                {/* <div className="container relative">
                   
                </div> */}
            </section>

            <section className="relative md:py-24 py-16 overflow-hidden">
                 <div className="container ">
                    <div className="grid grid-cols-1 pb-8 text-center">
                        <h3 className="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold">아직도 카카오톡과 텔레그램으로 그룹채팅방을 운영하시나요?</h3>

                        <p className="text-slate-400 max-w-xl mx-auto">나만의 오픈채팅 채널을 만들고 마켓팅해보세요.<br></br>
                            특정주제를 가지는 마켓팅용 전문 오픈채팅채널을 개설해보세요.</p>
                    </div>

                    <div className="grid md:grid-cols-12 grid-cols-1 gap-[30px] mt-8">
                        {KeyFeature.map((item,index)=>{
                            const Icon = item.Icon
                            return(
                                <div className="lg:col-span-4 md:col-span-6" key={index}>
                                <div className="flex transition-all duration-500 hover:scale-105 shadow dark:shadow-gray-800 hover:shadow-md dark:hover:shadow-gray-700 ease-in-out items-center p-3 rounded-md bg-white dark:bg-slate-900">
                                    <div className="flex items-center justify-center h-[45px] min-w-[45px] -rotate-45 bg-gradient-to-r from-transparent to-indigo-600/10 text-indigo-600 text-center rounded-full me-3">
                                        <Icon className="h-5 w-5 rotate-45"></Icon>
                                    </div>
                                    <div className="flex-1">
                                        <h4 className="mb-0 text-lg font-medium">{item.title}</h4>
                                    </div>
                                </div>
                            </div>
                            )
                        })}
                    </div>

                    
                </div>

                <div className="container relative md:mt-24 mt-16">
                    <div className="grid grid-cols-1 pb-8 text-center wow animate__animated animate__fadeInUp" data-wow-delay=".1s">
                        <h3 className="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold">기존 플랫폼의 채팅방운영 문제를 해결한 오픈챗</h3>
                        <p className="text-slate-400 max-w-xl mx-auto">카카오톡 텔레그램등의 계정생성의 불편함을 모두 해결한 채팅서비스입니다.
                                    오픈채팅방 운영시 계정생성의 불편함 및 오류를 해결</p>
                    </div>

                    <div className="grid md:grid-cols-2 grid-cols-1 items-center mt-16 gap-[30px]">
                        <div className="relative wow animate__animated animate__fadeInLeft" data-wow-delay=".3s">
                            <img src={classic02} className="rounded-lg shadow-md dark:shadow-gray-800" alt="" />
                            <div className="overflow-hidden absolute lg:h-[400px] h-[320px] lg:w-[400px] w-[320px] bg-indigo-600/5 bottom-0 start-0 rotate-45 -z-1 rounded-3xl"></div>
                        </div>

                        <div className="lg:ms-8 wow animate__animated animate__fadeInRight" data-wow-delay=".3s">
                            <h4 className="mb-4 text-2xl leading-normal font-medium"> 유용한 채팅방의 기능을 구현하고 <br /> 여러분의 문제를 해결했습니다 </h4>
                            <p className="text-slate-400"> 가장쉬운 채팅방 생성 서비스로서 대화방 관리기능이 구현되어 있습니다. 나만의 오픈채팅 채널을 만들고 새로운 마켓팅을해보세요.</p>
                            <ul className="list-none space-x-1 text-slate-400 mt-4">
                                <li className="mb-1 flex"><i className="uil uil-check-circle text-indigo-600 text-xl me-2"></i> 대화금지어 설정가능</li>
                                <li className="mb-1 flex"><i className="uil uil-check-circle text-indigo-600 text-xl me-2"></i> 회원입장시 인사봇 회원입장 문구 설정 </li>
                                <li className="mb-1 flex"><i className="uil uil-check-circle text-indigo-600 text-xl me-2"></i> 대화입장 on/off 설정</li>
                                <li className="mb-1 flex"><i className="uil uil-check-circle text-indigo-600 text-xl me-2"></i> 현재 대화방 참여자 상황을 실시간으로 확인가능</li> 
                                <li className="mb-1 flex"><i className="uil uil-check-circle text-indigo-600 text-xl me-2"></i> 쉽고 편리한 계정생성 및 관리기능 </li> 
                            </ul>
 
                        </div>
                    </div>
                </div>

                <div className="container relative md:mt-24 mt-16">
                    <div className="grid md:grid-cols-2 grid-cols-1 items-center gap-[30px]">
                        <div className="relative order-1 md:order-2 wow animate__animated animate__fadeInRight" data-wow-delay=".5s">
                            <img src={classic03} className="rounded-lg shadow-md dark:shadow-gray-800" alt="" />
                            <div className="overflow-hidden absolute lg:h-[400px] h-[320px] lg:w-[400px] w-[320px] bg-indigo-600/5 bottom-0 end-0 rotate-45 -z-1 rounded-3xl"></div>
                        </div>

                        <div className="lg:me-8 order-2 md:order-1 wow animate__animated animate__fadeInLeft" data-wow-delay=".5s">
                            <h4 className="mb-4 text-2xl leading-normal font-medium">기존 문자마케팅의 문제를 해결한 오픈챗</h4>
                            <p className="text-slate-400">기존의 문자마케팅의 경우
                                                          문자에 웹페이지 링크를 담아서 전달하는 방식으로서
                                                            단뱡향적인 정보전달의 역할만을 하였는데 문자메시지를 전문적인 대화채널로 확장하여 고객의 문의를 직접적으로 해결합니다.
</p>
                            <ul className="list-none space-x-1 text-slate-400 mt-4">
                                <li className="mb-1 flex"><i className="uil uil-check-circle text-indigo-600 text-xl me-2"></i> 문자마케팅 회원유입 경로 추적가능 </li>
                                <li className="mb-1 flex"><i className="uil uil-check-circle text-indigo-600 text-xl me-2"></i> 바탕화면에 추가 시 알림수신까지 가능</li>
                                <li className="mb-1 flex"><i className="uil uil-check-circle text-indigo-600 text-xl me-2"></i> 문자발송 마켓팅시 링크클릭만으로 입장가능</li>
                                <li className="mb-1 flex"><i className="uil uil-check-circle text-indigo-600 text-xl me-2"></i> 문자발송 메시지 클릭한 고객유입경로 추적</li>
                            </ul>

                        
                        </div>
                    </div>
                </div>

                
            </section>

            <section className="relative md:py-24 py-16 md:pt-0 pt-0">
                <div className="container relative">
                    <div className="grid grid-cols-1 justify-center wow animate__animated animate__fadeInUp" data-wow-delay=".1s">
                        <div className="relative z-1">
                            <div className="grid grid-cols-1 md:text-start text-center justify-center">
                                <div className="relative">
                                 
                                    <div className='h-28 mt-16'>   </div>
                                    <div className="absolute bottom-2/4 translate-y-2/4 start-0 end-0 text-center">
                
                                    </div>
                                </div>
                            </div>
                           
                            <div className="content md:mt-8">
                                <div className="grid lg:grid-cols-12 grid-cols-1 md:text-start text-center justify-center">
                                    <div className="lg:col-start-2 lg:col-span-10">
                                        <div className="grid md:grid-cols-2 grid-cols-1 items-center">
                                            <div className="mt-8">
                                                <div className="section-title text-md-start">
                                                    <h6 className="text-white/50 text-lg font-semibold">네오스폰서가 만드는 오픈챗 서비스</h6>
                                                    <h3 className="md:text-3xl text-2xl md:leading-normal leading-normal font-semibold text-white mt-2">
                                                        저희 오픈챗 서비스는<br></br>기업의 마케팅역량의 퀀텀점프를 위해
                                                        혁신적 툴을 이용하는<br/> 스타트업 서비스입니다.
                                                    </h3>
                                                </div>
                                            </div>

                                            <div className="mt-8">
                                                <div className="section-title text-md-start">
                                                    <p className="text-white/50 max-w-xl mx-auto mb-2">
                                                    네오스폰서는 마케팅 효율을 극대화하기 위해<br/>
                                                    기업과 고객을 연결하는 소통채널을 개발하고 있으며
                                                    새로운 마켓팅툴들을 실험하는 린스타트업입니다.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="absolute bottom-0 start-0 end-0 sm:h-2/3 h-4/5 bg-gradient-to-b from-indigo-500 to-indigo-600"></div>
            </section>

            <section className="relative md:py-24 py-16">
                <div className="container relative">
                    <div className="grid lg:grid-cols-12 grid-cols-1 items-center gap-[30px] wow animate__animated animate__fadeInUp" data-wow-delay=".1s">
                        <div className="lg:col-span-5">
                       
                        </div>
                    </div>
                </div>
   
            </section>
 
        </>
    )
}
